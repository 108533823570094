@import '~sanitize.css';
@import '~sanitize.css/forms.css';
@import '~sanitize.css/typography.css';

@import "custom";
@import "bootstrap";

html,
body {
  height: 100%;
  font-family: Helvetica, Trebuchet MS, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
}


a {
  text-decoration: none;
}

body {
  color: $base-font-color;
  text-align: center;
  #content {
    flex: 1 0 auto;
  }
  #footer {
    flex-shrink: initial;
  }
}

#content {
  header {
    p {
      font-size: 1.25rem;
      @include media-breakpoint-up(md) {
        font-size: 1.3rem;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
      }
    }
    .logo {
      height: 5rem;
      margin: 2rem;
      @include media-breakpoint-up(md) {
        height: 7rem;
        margin: 4rem;
      }
      @include media-breakpoint-up(lg) {
        height: 8rem;
        margin: 5rem;
      }
    }
  }
}

#footer {
  .logo {
    height: 2rem;
    margin: 0.5rem;
  }
  color: #767676;
}

.contact-section {
  margin: 4rem auto;
  font-size: 1.125rem;
  @include media-breakpoint-up(lg) {
    margin: 6rem auto;
    font-size: 1.25rem;
  }
  p {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  a {
    color: $base-font-color;
    font-size: 0.95rem;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      .far {
        margin-right: 0.5rem;
      }
    }
  }
}

.fas, .far {
  color: #006F89
}

.user-photo {
  border-radius: 50%;
  max-height: 100px;
}

h1.owner-name {
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 0;
  font-size: 2rem;
}

.contact-data {
  > span:not(:last-child) {
    margin-right: 1rem;
  }
}
